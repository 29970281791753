.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.disabled-div {
  background-color: black;
  opacity: 0.5;
}

.center-spinner {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 200;
}

.form-section-h1 {
  font-size: 2.2rem !important;
  line-height: 3rem !important;
  font-weight: 700 !important;
}
