.Help {
  width: 100%;
  padding: 3rem 1rem;
}

.HelpTopicExpandable__content {
  margin: 0 2.5rem;
}

.HelpTopicExpandable__item {
  border: 1px solid var(--awsui-color-grey-300);
  padding: 0.5rem;
}

.HelpTopicExpandable__item:not(:first-child) {
  margin-top: -1px;
}

/* Override expandable icon position to support using a heading as text */
.HelpTopicExpandable__item
  .awsui-expandable-section
  .awsui-expandable-section-header-icon {
  margin-top: 0.5rem;
}
