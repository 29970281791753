.footer {
  padding: 1rem 1rem;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  background-color: var(--awsui-color-grey-150);
}

.item {
  margin: 0 1rem;
}
